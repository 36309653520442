/*
* authsign.scss
* 
* This file contains all authsign module styles
*
*/
/*
* variables.scss
*
* Variables and settings
*
*/
/**
 * Foundation for Sites by ZURB
 * Version 6.2.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*
* overrides.scss
*
*/
/* Modal overrides */
.au-sign-modal .modal {
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0; }

/* Spinner overrides */
.au-static-spinner {
  margin-bottom: 1.5rem; }
  .au-static-spinner svg {
    position: static; }

.au-signing-spinner > div,
.au-signing-spinner .nd-spinner__content {
  height: 100%; }

.au-signing-spinner .nd-spinner__progress {
  margin-top: 2rem; }

/* Input error overrides */
.au-input-container .ng-invalid-auvalidator ~ label,
.au-input-container .ng-invalid-pattern ~ label,
.au-input-container .ng-invalid-unique-name ~ label {
  color: #b00000 !important; }

.au-input-container .au-device-list-device-name.ng-invalid-required,
.au-input-container .au-device-list-device-name.ng-invalid-minlength,
.au-input-container .ng-invalid-unique-name,
.au-input-container .ng-invalid-pattern,
.au-input-container .ng-invalid-auvalidator {
  border-color: #b00000 !important; }

/*
* helpers.scss
*
*/
.au-fluid {
  width: 100%; }

.au-collapse {
  margin-right: 0;
  margin-bottom: 0; }

.au-collapse-right {
  margin-right: 0; }

.au-collapse-bottom {
  margin-bottom: 0; }

.au-text-nowrap {
  white-space: nowrap; }

.au-flex-nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }

.au-flex-center-vertical {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }

.au-flex-center {
  -ms-flex-align: center;
      align-items: center; }

.au-large-margin {
  margin-bottom: 1.5rem; }

.au-medium-margin {
  margin-bottom: 1rem; }

.au-small-margin {
  margin-bottom: 0.5rem; }

.au-flex {
  display: -ms-flexbox;
  display: flex; }

.au-button-container {
  display: table;
  margin: 0 auto; }
  .au-button-container > div {
    display: table-cell;
    width: 50%;
    padding: 0 0.75rem; }
  .au-button-container button {
    width: 100%;
    white-space: nowrap; }

.au-section-divider {
  margin-bottom: 1.5rem;
  margin-top: 0; }

.au-action-column {
  min-width: 15rem; }
  .au-action-column > div {
    margin-bottom: 1rem; }

.au-notification nd-notification {
  margin-bottom: 1rem;
  display: block; }

.button--au-transparent {
  background: none;
  color: #0000a0;
  border-color: transparent; }
  .button--au-transparent[disabled] {
    background: none;
    color: #c9c7c7;
    border-color: transparent; }

.au-button-min-width {
  min-width: 11.25rem; }

/*
* animations.scss
*
*/
.au-fade.ng-hide {
  opacity: 0; }

.au-fade {
  transition: opacity 250ms; }

.au-fade-add,
.au-fade-remove {
  transition: opacity 200ms; }

.au-fade-add.au-fade-add-active {
  opacity: 0; }

.au-fade-remove.au-fade-remove-active {
  opacity: 1; }

/*
* agreements.scss
*
*/
.au-agreements-container {
  background: #ffffff;
  margin-bottom: 1.5rem; }

.au-agreements {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-align: left; }
  .au-agreements .au-agreements__title {
    float: left;
    margin-bottom: 1rem; }
  .au-agreements md-input-container {
    margin: 0; }
  .au-agreements h3 {
    margin-bottom: 1rem; }

.au-agreements--no-search .au-agreements__title {
  margin-bottom: 1.5rem; }

.au-agreements--no-active-agreements .au-agreements__title {
  display: none; }

.au-agreements--no-active-agreements .clearfix {
  text-align: center; }

.au-agreements--no-active-agreements .au-agreements__cancel {
  float: none; }

.au-agreement-item {
  position: relative;
  color: #0000a0;
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  cursor: pointer;
  border-top: 1px solid #e6e4e3;
  border-bottom: 1px solid transparent; }
  .au-agreement-item:last-child {
    border-bottom: 1px solid #e6e4e3; }
  .au-agreement-item:hover {
    background-color: #f1f8ff;
    border-top: 1px solid #c9e4ff;
    border-bottom: 1px solid #c9e4ff;
    text-decoration: none;
    outline: none; }
  .au-agreement-item__arrow {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem; }
    .au-agreement-item__arrow svg {
      width: 100%;
      height: 100%;
      stroke-width: 0.5px;
      color: #0000a0; }
  .au-agreement-item span {
    display: block;
    color: #000000;
    margin-top: -3px; }

.au-agreement-item:hover + .au-agreement-item {
  border-top: 1px solid transparent; }

.au-no-agreement-results {
  color: #c9c7c7; }
  .au-no-agreement-results .icon {
    margin-bottom: 1rem;
    color: #c9c7c7; }

.au-agreement-selection .login__service-information,
.au-agreement-selection .login__service-information-mobile,
.au-agreement-selection .test-authorization-actions,
.au-agreement-selection .login-page__header {
  display: none; }

.au-agreement-selection .au-login__box {
  position: relative; }

/*
* credentials.scss
*
*/
.au-credentials__form-container {
  -ms-flex: 1 0;
      flex: 1 0; }

.au-credentials__button-wrapper {
  padding: 0.75rem 0;
  margin-bottom: 1.5rem;
  text-align: center; }

.au-credentials__title {
  margin-bottom: 0.5rem;
  color: #000000; }

.au-user-id:-webkit-autofill {
  box-shadow: 0 0 0 100px #ffffff inset !important;
  -webkit-text-fill-color: #000000 !important; }
  .au-user-id:-webkit-autofill ~ label:not(.md-no-float) {
    transform: translate3d(0, 0, 0) scale(0.8125);
    color: #474748;
    padding-left: 19.69231px;
    top: -13px; }
  .au-user-id:-webkit-autofill ~ .nd-input-container__clear-button-container {
    visibility: visible;
    transform: scale(1); }
  .au-user-id:-webkit-autofill.ng-dirty.ng-empty:not(:focus) ~ label:not(.md-no-float) {
    transform: translate3d(0, 16px, 0) scale(1);
    padding-left: 1rem; }
  .au-user-id:-webkit-autofill.ng-dirty.ng-empty ~ .nd-input-container__clear-button-container {
    transform: scale(0);
    visibility: hidden; }

/*
* login.scss
*
* Login box and content
*
*/
.au-login {
  z-index: 80;
  pointer-events: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  padding-top: 150px;
  -webkit-transform: translateZ(0); }
  .au-login:focus {
    outline: none; }
  .au-login input::-ms-clear,
  .au-login input::-ms-reveal {
    display: none; }
  @media screen and (max-width: 29rem) {
    .au-login {
      padding-top: 4rem; } }

.au-login__container {
  pointer-events: auto;
  transform-style: preserve-3d;
  perspective: 1000px;
  position: relative;
  -ms-flex: 0 0 26rem;
      flex: 0 0 26rem;
  min-height: 33.75rem;
  margin-right: 1.5rem; }
  @media screen and (max-width: 29rem) {
    .au-login__container {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; } }

.au-login__box {
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%; }

.au-login__box--front {
  z-index: 2; }

.au-login__box--back {
  transform: rotateY(180deg); }

.au-login.logout .au-login__box--back {
  transform: rotateY(0); }

.au-login.logout .au-login__box--front {
  transform: rotateY(-180deg); }

.au-flip .au-login__box {
  transition: transform 500ms; }

.au-login__content {
  min-height: 28rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3); }
  @media screen and (max-width: 29rem) {
    .au-login__content {
      padding: 1rem; } }
  .au-login__content .au-input-container,
  .au-login__content .au-login-method-selector {
    margin-right: 0; }
  .au-login__content .au-login-method {
    margin-top: 1rem;
    font-size: 0.8125rem;
    color: #474748; }
  .au-login__content.au-flex-center {
    -ms-flex-direction: row;
        flex-direction: row; }
  .au-login__content.au-nemid {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background-color: inherit; }

.au-login--fullscreen {
  background: #ffffff;
  width: 100%;
  position: absolute;
  top: 4rem;
  left: 0;
  bottom: 0; }
  .au-login--fullscreen .au-credentials,
  .au-login--fullscreen .au-external-login {
    display: none; }
  .au-login--fullscreen .nd-spinner {
    height: 100%; }
  .au-login--fullscreen .au-login__container {
    width: 100%;
    min-height: 0; }
  .au-login--fullscreen .au-login__box {
    background-color: #ffffff; }
  .au-login--fullscreen .nd-spinner__progress {
    position: fixed; }

.au-login__loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms, visibility 400ms; }

.au-login--fullscreen .au-login__loader {
  position: fixed; }

.au-login--loading .au-login__loader {
  opacity: 1;
  visibility: visible; }

.au-login__notification {
  width: 100%;
  z-index: 2;
  pointer-events: auto; }
  .au-login__notification .nd-notification-set {
    background-color: #ffffff; }
  .au-login__notification .callout {
    box-sizing: content-box; }

[data-whatinput=touch] .au-external-login:focus {
  outline: none; }

.login-ndc {
  position: relative;
  padding: 1.5rem;
  background-color: #ffffff; }
  .login-ndc .au-login__content {
    padding: 0;
    box-shadow: none; }
  .login-ndc h2 {
    margin-bottom: 1rem;
    font-weight: 700; }
  .login-ndc md-input-container {
    margin-left: 0;
    margin-right: 0; }
  .login-ndc .login-divider-container {
    margin: 1.5rem 0; }
  .login-ndc .login-divider {
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #e6e4e3; }
  .login-ndc .login-cta {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
  .login-ndc .login-cta__button .button {
    width: 100%; }
  .login-ndc .login-cta__link {
    text-align: center;
    margin-top: 1.5rem;
    word-wrap: break-word; }
  .login-ndc__loading {
    padding: 1.5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
        flex-flow: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    position: absolute;
    background-color: #ffffff;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3; }
  .login-ndc__loading md-progress-circular {
    margin: 0; }
  @media (min-width: 768px) {
    .login-ndc h2 {
      font-size: 2.5rem; }
    .login-ndc .login-cta {
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: justify;
          justify-content: space-between; }
    .login-ndc .login-cta__button .button {
      width: auto; }
    .login-ndc .login-cta__link {
      text-align: left;
      margin-top: 0;
      padding-right: 1rem;
      -ms-flex-order: -1;
          order: -1; }
    .login-ndc .logged-out {
      display: block;
      padding: 1rem 0; }
    .login-ndc .logged-out h2 {
      margin-bottom: 2rem; } }

/*
* issuing.scss
*
* Issuing page and content
*
*/
.au-issuing-hide-overflow {
  overflow: hidden; }

.au-modal__content.au-issuing:not(.au-issuing-wait) {
  -ms-flex-align: start;
      align-items: flex-start; }

.au-issuing__logo-column {
  min-width: 11.250rem; }
  @media screen and (max-width: 74.9375em) {
    .au-issuing__logo-column {
      display: none; } }

.au-issuing__body-column {
  -ms-flex: 1;
      flex: 1;
  max-width: 100%; }

.au-issuing__logo-container {
  background: #ffffff;
  padding-bottom: 56.25%;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.08);
  position: relative; }
  .au-issuing__logo-container img {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%); }

.au-issuing__title {
  margin-bottom: 2rem; }

.au-margin-top-medium {
  margin-top: 1rem; }

.au-issuing__radio-group > .row:not(:last-child) {
  margin-bottom: 2rem; }

.au-issuing__radio-group md-radio-button {
  line-height: 1.5;
  margin-bottom: 0; }
  .au-issuing__radio-group md-radio-button ._md-label {
    margin-left: calc(2rem + 12px); }

.au-issuing__radio-button-info {
  display: block; }

.au-issuing__bankid-type {
  font-size: 1rem;
  font-weight: bold; }

.au-issuing-tac {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem; }
  .au-issuing-tac md-checkbox {
    margin-bottom: 0; }

.au-issuing__bankID-select:hover md-radio-button:not([disabled]) ._md-off {
  background: #ffffff; }

.au-issuing__bankID-icon {
  cursor: pointer;
  height: 7.2rem; }

.au-issuing .row + .au-button-container {
  margin-top: 1rem; }

.au-issuing-codeapp__top-notification {
  position: absolute;
  left: 0;
  top: 4rem;
  width: 100%;
  min-width: 61.25rem;
  text-align: center; }

.au-notification-icon {
  position: relative;
  padding-left: 2.5rem; }
  .au-notification-icon .icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1rem; }

.au-issuing-codeapp .small-3.columns {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.au-issuing-codeapp .small-6.columns {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.au-issuing-codeapp .code-app-issuing-spinner {
  position: absolute;
  top: calc(50% + 2rem);
  left: 50%;
  margin: -25px 0 0 -25px; }

@media screen and (min-width: 81em) {
  .au-issuing-codeapp .small-3.columns {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .au-issuing-codeapp .small-6.columns {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; } }

.au-issuing-steps {
  width: 33rem;
  margin: 0.5rem auto 2rem auto; }

.au-issuing-codeapp--step3 .au-issuing-steps {
  margin-top: 4.5rem; }

/*
* Signing.scss
*
*/
.au-signing {
  background-color: #e5f2ff;
  padding: 1rem; }

.au-modal .au-signing {
  background-color: #ffffff; }

.au-signing-hide-overflow {
  overflow: hidden; }

.au-signing-select-container {
  min-width: 400px;
  margin-bottom: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .au-signing-select-container .au-radio-container {
    margin-bottom: 0;
    padding-right: 1rem; }
  .au-signing-select-container .au-signing-method-logos {
    padding-left: 1rem;
    -ms-flex: 1 auto;
        flex: 1 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end; }

.au-signing-container {
  margin: 1rem; }
  .au-signing-container h1 {
    color: #000000; }
  .au-signing-container.ng-enter {
    transition: opacity 250ms;
    opacity: 0; }
  .au-signing-container.ng-enter-active {
    opacity: 1; }

.iframe-container {
  height: 98vh; }
  .iframe-container iframe {
    height: inherit; }

/*
* modal.scss
*
*
*/
.au-modal .modal {
  padding: 0;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.au-modal__header {
  position: relative;
  min-width: 61.25rem;
  background: #0000a0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 4rem;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .au-modal__header h1 {
    margin: 0;
    font-size: 1.7rem; }

.au-modal__close-button {
  position: absolute;
  top: 50%;
  margin-top: -1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem; }
  .au-modal__close-button .icon {
    width: 100%;
    height: 100%;
    transition: color 250ms; }
  .au-modal__close-button svg {
    width: 100%;
    height: 100%; }
  .au-modal__close-button:hover .icon {
    color: #fff; }

.au-modal__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  min-width: 61.25rem;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 1.5rem 0; }

/*
*
* trusted-devices.scss
*
*/
.au-trusted-devices__row:not(:first-child) {
  margin-top: 1rem; }

.au-trusted-devices__device-name {
  line-height: 1;
  font-weight: bold;
  font-size: 90%;
  text-transform: uppercase; }

.au-trusted-devices-wrapper {
  min-height: 40px; }

/*
* code-app-device-list.scss
*
* View my code apps styles
*/
.au-device-list {
  margin-bottom: 2.5rem; }

.au-device-list__input {
  width: 300px; }

.au-device-list th,
.au-device-list td {
  text-align: center;
  padding: 0 0.75rem; }
  .au-device-list th:first-child,
  .au-device-list td:first-child {
    text-align: left; }

.au-device-list th:first-child {
  padding-left: calc(8px + 0.75rem); }

.au-device-list td {
  padding: 15px 0.75rem 0 0.75rem; }
  .au-device-list td:first-child {
    padding-top: 0; }

.au-device-list .link {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.au-device-list .link[disabled] {
  color: #c9c7c7;
  pointer-events: none;
  cursor: default; }
  .au-device-list .link[disabled]:hover {
    text-decoration: none; }

.au-device-list__switch {
  height: auto;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex; }
  .au-device-list__switch ._md-container {
    margin: 0; }
  .au-device-list__switch:last-of-type {
    margin: 0 0.5rem; }

.au-device-list__row--disabled .au-device-list__date-time {
  color: #c9c7c7; }
