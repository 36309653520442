/**
 * Foundation for Sites by ZURB
 * Version 6.2.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.language-menu li {
  list-style: none;
  display: inline-block;
  line-height: 3.1rem; }

.language-menu li::before {
  color: #ffffff;
  content: '\|';
  font-size: 1.3rem;
  font-weight: normal; }

.language-menu li:first-child::before {
  content: '';
  display: none; }

.language-menu li:last-child a {
  padding-right: 0; }

.language-menu a {
  color: #ffffff;
  padding-left: 0.25em;
  padding-right: 0.25em; }

.language-menu .active {
  font-weight: 700; }

.language-menu-mobile {
  position: relative; }
  .language-menu-mobile__select-overlay {
    position: absolute;
    height: 3.1rem;
    line-height: 3.1rem;
    right: 0;
    pointer-events: none;
    color: white;
    background-color: #00005e;
    padding-left: 2rem;
    font-weight: 100;
    white-space: nowrap; }
    .language-menu-mobile__select-overlay--focused .icon {
      transform: rotate(180deg); }
  .language-menu-mobile__select-overlay-arrow {
    display: inline-block; }
    .language-menu-mobile__select-overlay-arrow .icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-bottom: 3px; }
      .language-menu-mobile__select-overlay-arrow .icon svg {
        width: 100%;
        height: 100%;
        color: #ffffff; }
  .language-menu-mobile__select {
    height: 3.1rem;
    border: none;
    background-color: transparent;
    color: #00005e;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer; }
    .language-menu-mobile__select option {
      color: #000000;
      direction: rtl; }

.login__service-information,
.login__service-information-mobile {
  color: #000000;
  background-color: #ffe183; }
  .login__service-information.login__service-information--has-body,
  .login__service-information-mobile.login__service-information--has-body {
    cursor: pointer; }

.login__service-information {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  overflow: hidden; }
  .login__service-information .login__service-information-content {
    height: 4rem;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (min-width: 29rem) {
      .login__service-information .login__service-information-content {
        margin-left: 0.75rem; } }
  .login__service-information .login__service-information-content-showmore,
  .login__service-information .login__service-information-content-title,
  .login__service-information .login__service-information-content-body {
    height: 4rem;
    display: inline-block; }
  .login__service-information .login__service-information-content-title {
    font-weight: bold;
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .login__service-information .login__service-information-content-title::after {
      content: ':'; }
  .login__service-information .login__service-information-content-body {
    margin-left: 0.5rem;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    padding-right: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .login__service-information .login__service-information-content-showmore {
    text-decoration: underline;
    color: #000000;
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    @media screen and (min-width: 29rem) {
      .login__service-information .login__service-information-content-showmore {
        margin-right: 0.75rem; } }

.login__service-information-mobile {
  position: relative; }
  .login__service-information-mobile .login__service-information-content-mobile {
    padding: 1rem 0;
    line-height: 1.2; }
  .login__service-information-mobile .login__service-information-content-body-mobile {
    margin-top: 0.1rem; }
  .login__service-information-mobile .login__service-information-content-showmore-mobile {
    display: inline-block;
    text-decoration: underline; }
  .login__service-information-mobile .login__service-information-content-title-mobile {
    font-weight: bold; }
  @media (min-width: 768px) {
    .login__service-information-mobile {
      position: absolute;
      margin-bottom: 0; } }
  @media screen and (min-width: 29rem) {
    .login__service-information-mobile {
      width: 100%; } }

.login__service-information-modal .modal {
  width: 100%;
  height: 100%;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  max-width: none;
  max-height: none; }

.login__service-information-modal .login__service-information-modal-header {
  height: 4rem;
  line-height: 4rem;
  background-color: #ffe183;
  color: #000000;
  font-size: 1.5rem;
  text-align: center;
  padding-left: 4rem; }
  .login__service-information-modal .login__service-information-modal-header .login__service-information-modal-header-type {
    display: inline-block; }
  .login__service-information-modal .login__service-information-modal-header .login__service-information-modal-header-close {
    width: 2rem;
    height: 2rem;
    float: right;
    line-height: 0;
    cursor: pointer;
    margin: 1rem 30px 1rem 1rem; }
    .login__service-information-modal .login__service-information-modal-header .login__service-information-modal-header-close svg {
      width: 100%;
      height: 100%;
      color: #000000; }
    .login__service-information-modal .login__service-information-modal-header .login__service-information-modal-header-close:hover .icon {
      color: #474748; }
    .login__service-information-modal .login__service-information-modal-header .login__service-information-modal-header-close:active .icon {
      color: #8b8a8d !important; }

.login__service-information-modal .login__service-information-modal-body img {
  display: block; }

.login__service-information-modal .login__service-information-modal-title h2 {
  font-size: 48pt;
  line-height: 48pt;
  color: #000000; }

.login__service-information-modal .login__service-information-modal-content {
  width: 50%;
  margin: 8rem auto 0 auto;
  color: #000000; }

.login-menu-bar {
  z-index: 20;
  width: 100%;
  height: 3.1rem;
  background-color: #00005e; }
  .login-menu-bar .logo-white {
    line-height: 3.1rem; }
    .login-menu-bar .logo-white img {
      display: inline-block;
      height: 15px;
      width: 5.4rem;
      margin-left: -3.5px; }

.login-background {
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  background-color: #00005e;
  background-image: url("assets/login/img/mobile_breakpoint_768x1080.jpg"); }

.login-page {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - 3.75rem - 3.1rem); }
  .login-page > .fluid-container {
    padding: 0; }
  .login-page nasa-login {
    max-width: 100%; }
  .login-page .nasa-login .login-ndc {
    border-radius: 8px;
    padding: 1.5rem; }
    .login-page .nasa-login .login-ndc .login-header {
      margin-bottom: 2rem;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4; }
    .login-page .nasa-login .login-ndc .login-info {
      margin-bottom: 2rem; }
  .login-page__text {
    min-height: 5.5rem;
    padding: 1.5rem;
    color: #ffffff; }
    .login-page__text h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-bottom: 1rem; }
    .login-page__text .from-tridion,
    .login-page__text .from-tridion p {
      font-size: 1rem !important;
      line-height: 1.35 !important; }
    .login-page__text .from-tridion a {
      color: #ffffff;
      text-decoration: underline; }
      .login-page__text .from-tridion a:hover, .login-page__text .from-tridion a:focus {
        color: #3399ff; }
    .login-page__text .from-tridion ul {
      margin-top: 0.5rem;
      margin-left: 2rem; }
    .login-page__text .from-tridion .visually-hidden {
      display: none; }
  .login-page__text-mobile {
    background-color: #e5f2ff;
    color: #0000a0;
    padding: 2.5rem 1.5rem; }
    .login-page__text-mobile h2 {
      color: #0000a0;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.5rem; }
    .login-page__text-mobile .from-tridion p {
      font-size: 1rem !important; }
    .login-page__text-mobile .from-tridion > p:last-child {
      margin-bottom: 0; }
    .login-page__text-mobile a {
      width: 100%; }
  .login-page__links ul {
    list-style: none;
    margin: 1.5rem 0 1.5rem 0; }
  .login-page__links li {
    display: block;
    margin: 0; }
  .login-page__links a, .login-page__links li {
    font-size: 0.8125rem;
    color: white; }
  .login-page__links .visually-hidden {
    display: none; }
  .login-page__authsign-wrapper {
    width: 100%; }

.login-page-contact {
  margin-top: 0;
  padding: 1.5rem;
  background-color: #000040;
  color: white; }
  .login-page-contact--nasa {
    width: 24rem;
    border-radius: 8px; }
  .login-page-contact p:last-child {
    margin-bottom: 0; }

.login-footer {
  min-height: 3.75rem; }
  .login-footer .login-contact-info {
    color: #ffffff;
    font-size: 0.8125rem;
    display: -ms-flexbox;
    display: flex;
    padding-top: 3px; }

@media (min-width: 540px) {
  .login-background {
    background-image: url("assets/login/img/login_view_1920x893.jpg");
    background-position-y: 3.1rem; }
  .login-page {
    padding-bottom: 2rem; }
    .login-page .from-tridion {
      margin-bottom: 1.5rem; }
  .login-page > .fluid-container {
    padding: 7rem 0.75rem 0 0.75rem; }
  .login-page .login-page__text {
    margin-top: 0;
    padding: 0; }
  .login-page .login-page__text h1 {
    text-align: left;
    margin-bottom: 2rem; }
  .login-page .login-page__links {
    background: none;
    padding: 1rem; }
    .login-page .login-page__links a {
      color: #ffffff; }
  .login-page__links li {
    margin: 0 2rem 0 0;
    display: inline-block; }
  .login-page__links ul {
    margin: 0; }
  .login-page-contact {
    margin-top: 1.5rem; } }

@media (min-width: 768px) {
  .login-page .login-page__text h1 {
    font-size: 2.5rem; }
  .login-page .from-tridion,
  .login-page .from-tridion p {
    font-size: 1.25rem !important; } }

.login-page .login-ndc,
.login-page .login-ndc__loading {
  border-radius: 8px; }

.login-page .au-login {
  padding: 0; }

.login-page .au-login__box--front {
  position: relative; }

.login-page .au-login__box--back {
  top: 0; }

.login-page .au-login__container {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  min-height: auto;
  margin: 0; }

.login-page .au-nemid .info-links-content {
  background-color: #0000a0;
  padding: 10px; }
  .login-page .au-nemid .info-links-content a {
    color: #ffffff; }

.login-page .au-nemid iframe {
  display: block; }

.login-page .au-login__content {
  border-radius: 8px; }

.login-page .au-login__content.au-nemid {
  padding: 0;
  background-color: #ffffff;
  height: 450px; }

.login-page .test-authorization-actions {
  margin-top: 2rem; }
  .login-page .test-authorization-actions > div > div {
    display: inline-block; }
  .login-page .test-authorization-actions .button {
    font-size: 0.8125rem; }

.login-page .au-login__loader {
  border-radius: 8px; }

.login-page.nasa-page {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 3rem 0;
  -ms-flex-direction: column;
      flex-direction: column; }
  .login-page.nasa-page .align-to-center {
    -ms-flex-align: center;
        align-items: center; }
  .login-page.nasa-page .justify-space-between {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
  .login-page.nasa-page .justify-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end; }
  .login-page.nasa-page .wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .login-page.nasa-page .nasa-login-row {
    width: 100%; }
  .login-page.nasa-page .login-cta__link {
    padding-top: 1.5rem; }

.nasa-page-container {
  display: -ms-flexbox;
  display: flex; }

@media screen and (min-width: 769px) {
  .nasa-login .login-ndc {
    width: 24rem;
    max-width: 100%; } }

@media screen and (max-width: 539px) {
  .login-background {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
  .login-box {
    -ms-flex: 1;
        flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between; }
  .login-page {
    min-height: 0; }
    .login-page.nasa-page .justify-content {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center; }
    .login-page .login-ndc,
    .login-page .login-ndc__loading {
      border-radius: 0; }
  .login-footer .login-contact-info {
    padding-left: 12px; }
  .login-footer a {
    text-decoration: underline; }
  .login-page-contact {
    background-color: transparent; } }

.login-list ul {
  margin-bottom: 0;
  margin-top: 4px; }
  .login-list ul li:not(:last-child) {
    margin-bottom: 4px; }

.nasa-login__help-text {
  margin-top: 2rem; }
  .nasa-login__help-text ul {
    margin-bottom: 0; }
  .nasa-login__help-text p {
    margin-bottom: 1rem; }

.login-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%; }

/**
 * Foundation for Sites by ZURB
 * Version 6.2.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
#test-authorization-actions {
  pointer-events: auto; }
  #test-authorization-actions .button {
    margin: 0.5em 1em 0.5em 0; }
  #test-authorization-actions h1,
  #test-authorization-actions h2,
  #test-authorization-actions h3,
  #test-authorization-actions h4,
  #test-authorization-actions p {
    color: #ffffff; }
  #test-authorization-actions .test-authorization-actions__test-login {
    display: inline-block; }
  #test-authorization-actions .inputted-customer-login-info {
    margin-bottom: 10px; }
    #test-authorization-actions .inputted-customer-login-info .ncc-input {
      min-height: 0; }
      #test-authorization-actions .inputted-customer-login-info .ncc-input .ncc-input__field-container {
        width: 180px; }
  #test-authorization-actions table {
    background: white;
    min-width: 486px;
    border-radius: 8px; }
    #test-authorization-actions table td:first-child {
      padding-left: 10px;
      width: 238px; }

@media screen and (max-width: 539px) {
  #test-authorization-actions table {
    min-width: 0; }
    #test-authorization-actions table td:first-child {
      padding-left: 0; } }
